import sigma from './gigachad.gif';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={sigma} alt="sigma" />
        <p>
          What are you doing here? <br />
          You are a sigma, not a beta!
        </p>
        <a
          className="App-link"
          href="https://www.propulse.no/"
          rel="noopener noreferrer"
        >
          Get back to the main site, king.
        </a>
      </header>
    </div>
  );
}

export default App;
